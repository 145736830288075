import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Schedule from './Pages/Schedule';
import Challenges from './Pages/Challenges';
import Login from './Pages/Login';
import Leaderboard from './Pages/Leaderboard';
import Team from './Pages/Team';
import Responses from './Pages/Responses';
import Register from './Pages/Register';
import Analytics from './Pages/Analytics';
import { useAuth } from './Contexts/AuthContext';


import MainLayout from './Layouts/MainLayout';

const App = () => {
  const { session, user } = useAuth();

  return (
    <Router>
      {session && user && (
        <Routes>
          {user.onboarding_complete ? (
            <Route element={<MainLayout />}>
              <Route path="/" element={<Navigate to="/challenges" />} />
              <Route path="*" element={<Navigate to="/challenges" />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/challenges" element={<Challenges />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/responses/:challenge_id" element={<Responses />} />
            </Route>
          ) : (
            <>
              <Route path='/register' element={<Register />} />
              <Route path="*" element={<Navigate to="/register" />} />
            </>
          )}
        </Routes>
      )}
      {!session && (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
